<template functionnal>
  <section class="section">
    <div class="template pb-4">
      <div class="template__content mb-2">
        <h1>Mention légales</h1>
        <br />

        <div>
          <h5>Éditeur du site</h5>
          <p>
            Le site www.femmesinitiatives.org est édité par:
            <pre>
  Association Femmes Initiatives
  7 rue Lachelier
  75013 Paris
  Téléphone : 01 44 23 74 33
            </pre>
          </p>
        </div>

        <div>
          <h5>Directeur de la publication</h5>
          <p>
            Madame Félicité BILOA, présidente de l’association.
          </p>
        </div>

        <div>
          <h5>Hébergement du site</h5>
          <p>
            Le site est hébergé par :
            <pre>
  Amazon Web Services, Inc.
  P.O. Box 81226
  Seattle, WA 98108-1226
  USA
  http://aws.amazon.com
            </pre>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
